import React, { Component, useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavLink,
  NavItem,
  Row
} from 'reactstrap';

import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/tr';

import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import cookie from '../../utils/cookie';
import project from '../../utils/project';

import ExamWidget from '../../components/Exams';
import classnames from 'classnames';
const usersUrl = rest('users');
const quizzesUrl = rest('quizzes');
const activeQuizzesUrl = rest('activeQuizzes');
const _quizattemptsUrl = rest('quizattempts');
const _currentTimeUrl = rest('date');
const auth = rest('auth');
class Exams extends Component {
  
  constructor(props) {
    
      super(props);
      
      this.state = {
        quizAttempts: [],
        quizzes: [],
        formerExams : [],
        activeQuizzes: [],
        oldQuizzes: [],
        freeQuizzes: [],
        denemeQuizzes: [],
        subjectQuizzes: [],
        activeTab: '1',
        availableDeneme: 0,
        availableKonu: 0
      };

      moment.locale('tr');  //set date lang to 'tr'
  }

  componentWillMount() {
    this.getUserAvailableQuiz().then(() => {

    });
  }

  componentDidMount() {
    
    let self = this;
    let formerExams = [];
    let activeQuizzes = [];
    let oldQuizzes = [];
    _xhr.get({
        url: activeQuizzesUrl,
        onsuccess: function ({ data }) {
          self.getCurrentTime().then(() => {
            let quizzes = data;
            let currentTime = self.state.now;
                let quizOpenTime;
                let quizOpenTimeObj;
                let quizCloseTime;
                let quizCloseTimeObj;
            if(quizzes.length > 0){
              quizzes.forEach(function (quiz, i) {
                if(quiz.isOpen){
                  quizOpenTime = moment(quiz.timeOpen).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                  quizOpenTimeObj = moment(quizOpenTime);
                  quizCloseTime = moment(quiz.timeClose).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                  quizCloseTimeObj = moment(quizCloseTime);
                  quiz.isQuizOpened = false;
                  if (currentTime >= quizOpenTimeObj && currentTime <= quizCloseTimeObj) {
                    quiz.isQuizOpened = true;
                  }else if (currentTime > quizCloseTimeObj){
                    quiz.isQuizOpened = false;
                  }
                  if(!quiz.timeOpen) {
                    quiz.isQuizOpened = false;
                  }
                  if(quiz.isQuizOpened) {
                    activeQuizzes.push(quiz)
                  } else {
                    oldQuizzes.push(quiz)
                  }
                  formerExams.push(quiz);
                }
                
              })
            }
            if(activeQuizzes.length < 1) {
              self.setState({
                activeTab: '1'
              })
            }
            let freeQuizzes = oldQuizzes.filter(item => !item.isPremium);
            freeQuizzes = freeQuizzes.sort(function(a, b) {
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
            });
            let denemeQuizzes = oldQuizzes.filter(item => item.categoryId === 3 && item.isPremium);
            denemeQuizzes = denemeQuizzes.sort(function(a, b) {
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
            });

            let subjectQuizzes = oldQuizzes.filter(item => item.categoryId !== 3 && item.isPremium);
            subjectQuizzes = subjectQuizzes.sort(function(a, b) {
              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
            });
            self.setState({
              quizzes:formerExams,
              activeQuizzes: activeQuizzes,
              oldQuizzes: oldQuizzes,
              freeQuizzes: freeQuizzes,
              denemeQuizzes: denemeQuizzes,
              subjectQuizzes: subjectQuizzes
            })
            /* self.getMyQuizAttempts().then(() => {
              let quizzes = self.state.quizzes;
              let quizAttempts = self.state.quizAttempts;

              let currentTime = self.state.now;
              let quizOpenTime;
              let quizOpenTimeObj;
              let quizCloseTime;
              let quizCloseTimeObj;

              if(quizzes.length > 0){
                quizzes.forEach(function (quiz, i) {
                    if(quiz.isOpen && !_.find(quizAttempts, {quizId: quiz.id, completed: true})){
                        quizOpenTime = moment(quiz.timeOpen).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                        quizOpenTimeObj = moment(quizOpenTime);
                        quizCloseTime = moment(quiz.timeClose).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
                        quizCloseTimeObj = moment(quizCloseTime);
                        if (currentTime >= quizOpenTimeObj && currentTime <= quizCloseTimeObj) {
                          quiz.isQuizOpened = true;
                        }else if (currentTime > quizCloseTimeObj){
                          quiz.isQuizOpened = false;
                        }
                        formerExams.push(quiz);
                    }
                })
              }

              formerExams.sort(function compare(a, b) {
                var dateA = new Date(a.timeOpen);
                var dateB = new Date(b.timeOpen);
                return dateB - dateA;
              });
              self.setState({
                formerExams:formerExams
              })
            }) */
          });
        },
        onerror: function (error) {
          console.log(error)
        }
    });


  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

 

    

  getCurrentTime() {
    let self = this;
    return new Promise((resolve, reject) => {
        _xhr.get({
            url: _currentTimeUrl,
            onsuccess: function (response) {
              var now = moment(response.data.now).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
              self.setState({
                now: moment(now)
              })
              resolve();
            }
        });
    });
  }

  getMyQuizAttempts(){
    let self = this;

    let quizattemptsUrl = usersUrl + "/" + cookie.get(auth.user) + _quizattemptsUrl;
    let stringQuery = {
        filter: { "include": { "relation": "Quiz" } }
    };
    return new Promise((resolve, reject) => {
      _xhr.get({
          url: quizattemptsUrl,
          stringData: stringQuery,
          onsuccess: function ({ data }) {
            self.setState({
              quizAttempts:data
            })
            resolve();
          }
      });
    });
  }

  toggle(tab) {
    if(this.state.activeTab !== tab) this.state.activeTab = tab;
  }

  getUserAvailableQuiz() {
    let self = this;
    const userId = cookie.get(auth.user);
    return new Promise((resolve, reject) => {
      _xhr.get({
        url: rest('rest') + 'user/products/' + userId,
        onsuccess: function(response){
          console.log(response.data);
          let denemeCount = 0;
          let konuCount = 0;
          response.data.available.forEach( item => {
            if(item.count > 0) {
              if(item.product.quizCategoryId === 3) {
                denemeCount = denemeCount + item.count
              }
              if(item.product.quizCategoryId === 4) {
                konuCount = konuCount + item.count
              }
            }
          })
          self.setState({
            availableDeneme: denemeCount,
            availableKonu: konuCount
          })
          resolve();
        },
        onerror: function(error) {
          self.setState({
            availableQuiz: 0
          })
          resolve();
        }
      });
    });
  }
  
  render() {
    return (
        <Row>
          <Col>
            <Card>
              <CardHeader>

                <i className="fa fa-hourglass-1"></i>
                <b>SINAVLAR</b>
                <div className="card-header-actions">
                  <Badge color="primary" pill style={{marginRight: 10}}>
                    Deneme Hakkı: {this.state.availableDeneme}
                  </Badge>
                  <Badge color="primary" pill>
                    Konu Testi Hakkı: {this.state.availableKonu}
                  </Badge>
                  {/*<Badge pill color="danger" className="float-right">{this.state.quizzes.length}</Badge>*/}
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                <Col md="12">
                <div style={{width: '100%', marginBottom: '20px'}}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => this.setState({activeTab: '1'})}
                      >
                        <b>Ücretsiz Sınavlar</b>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => this.setState({activeTab: '2'})}
                      >
                        <b>Deneme Sınavları</b>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => this.setState({activeTab: '3'})}
                      >
                        <b>Konu Testleri</b>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab} md="12">
                    <TabPane tabId="1">
                      <Row>
                        {
                          this.state.freeQuizzes.length > 0 &&
                          this.state.freeQuizzes.map((formerExam, index) => {
                            return <Col md="4" key={index}>
                              <ExamWidget url={"/exams/" + formerExam.id}
                                          header={formerExam.name}
                                          invert={formerExam.isQuizOpened}
                                          isQuizOpened={formerExam.isQuizOpened}
                                          icon={formerExam.isUsed ? "icon-star" : formerExam.isPremium ? "icon-lock" : formerExam.isQuizOpened ? "icon-speedometer" : "icon-clock"}>
                                {formerExam.intro}<br/>
                                {formerExam.timeOpen ? (
                                  <span>BAŞLANGIÇ    :{moment(formerExam.timeOpen).format(project("dateFormat"))}<br/></span>
                                ) : (
                                  <span><br></br></span>
                                )}
                                {formerExam.timeClose ? (
                                  <span>BİTİŞ        :{moment(formerExam.timeClose).format(project("dateFormat"))}<br/></span>
                                ) : (
                                  <span><br></br></span>
                                )}
                                SÜRE         :{formerExam.timeLimit} DAKİKA</ExamWidget>
                            </Col>
                          })
                        }
                        {
                          this.state.freeQuizzes.length === 0 &&
                            <Col>
                              Ücretsiz sınavınız bulunmamaktadır.
                          </Col>
                        }
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        {
                          this.state.denemeQuizzes.length > 0 &&
                          this.state.denemeQuizzes.map((formerExam, index) => {
                            return <Col md="4" key={index}>
                              <ExamWidget url={"/exams/" + formerExam.id}
                                          header={formerExam.name}
                                          invert={formerExam.isQuizOpened}
                                          isQuizOpened={formerExam.isQuizOpened}
                                          icon={formerExam.isUsed ? "icon-star" : formerExam.isPremium ? "icon-lock" : formerExam.isQuizOpened ? "icon-speedometer" : "icon-clock"}>
                                {formerExam.intro}<br/>
                                {formerExam.timeOpen ? (
                                  <span>BAŞLANGIÇ    :{moment(formerExam.timeOpen).format(project("dateFormat"))}<br/></span>
                                ) : (
                                  <span><br></br></span>
                                )}
                                {formerExam.timeClose ? (
                                  <span>BİTİŞ        :{moment(formerExam.timeClose).format(project("dateFormat"))}<br/></span>
                                ) : (
                                  <span><br></br></span>
                                )}
                                SÜRE         :{formerExam.timeLimit} DAKİKA</ExamWidget>
                            </Col>
                          })
                        }
                        {
                          this.state.denemeQuizzes.length === 0 &&
                          <Col>
                            Deneme sınavınız bulunmamaktadır.
                          </Col>
                        }
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                    <Row>
                        {
                          this.state.subjectQuizzes.length > 0 &&
                          this.state.subjectQuizzes.map((formerExam, index) => {
                            return <Col md="4" key={index}>
                                      <ExamWidget url={"/exams/" + formerExam.id}
                                                  header={formerExam.name}
                                                  invert={formerExam.isQuizOpened}
                                                  isQuizOpened={formerExam.isQuizOpened}
                                                  icon={formerExam.isUsed ? "icon-star" : formerExam.isPremium ? "icon-lock" : formerExam.isQuizOpened ? "icon-speedometer" : "icon-clock"}>
                                        {formerExam.intro}<br/>
                                        {formerExam.timeOpen ? (
                                          <span>BAŞLANGIÇ    :{moment(formerExam.timeOpen).format(project("dateFormat"))}<br/></span>
                                        ) : (
                                          <span><br></br></span>
                                        )}
                                        {formerExam.timeClose ? (
                                          <span>BİTİŞ        :{moment(formerExam.timeClose).format(project("dateFormat"))}<br/></span>
                                        ) : (
                                          <span><br></br></span>
                                        )}
                                        SÜRE         :{formerExam.timeLimit} DAKİKA</ExamWidget>
                                  </Col>
                          })
                        }
                        {
                          this.state.subjectQuizzes.length === 0 &&
                            <Col>
                              Konu sınavı bulunmamaktadır.
                          </Col>
                        }
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
                </Col>
                  
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

    );
  }
}

export default Exams;
