import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row, Table
} from 'reactstrap';

import { Pie } from 'react-chartjs-2';
import _xhr from "../../utils/_xhr";
import rest from "../../utils/rest.cfg";
const _totalStatisticsUrl = rest('totalStatistics');
const _categoryStatisticsUrl = rest('categoryStatistics');
const _subjectStatisticsUrl = rest('subjectStatistics');
const data = {
  labels: ['Yanlış', 'Boş', 'Doğru'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 9, 38],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(75, 192, 192, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
class Statistics extends Component {
  constructor(props) {
      super(props);
      this.state = {
        totalStatistics: null,
        categoryStatistics: null,
        subjectStatistics: null,
        errorMessage: null,
      };
  }

  componentWillMount() {
  }

  secondsToHour(seconds) {
    return parseInt(parseInt(seconds) / ( 60 * 60 ));
  }

  secondsToMinute(seconds) {
    return parseInt(parseInt(seconds) / 60) % 60;
  }

  secondsToSeconds(seconds) {
    return parseInt(seconds) % 60;
  }

  getTotalStatistics() {
    let self = this;
    _xhr.get({
      url: _totalStatisticsUrl,
      onsuccess: function({data}) {
        const denemeCount = data.quizCategories.find(item => item.categoryName === 'Deneme Sinavi' || item.categoryName === 'Deneme Sınavı').count;
        data.denemeCount = denemeCount;
        data.konuCount = data.quizCount - denemeCount;
        data.totalTimeHour = self.secondsToHour(data.totalTime);
        data.totalTimeMinutes = self.secondsToMinute(data.totalTime);
        data.totalTimeSeconds = self.secondsToSeconds(data.totalTime);
        data.timePerQuestionHour = self.secondsToHour(data.timePerQuestion);
        data.timePerQuestionMinutes = self.secondsToMinute(data.timePerQuestion);
        data.timePerQuestionSeconds = self.secondsToSeconds(data.timePerQuestion);
        self.setState({
          totalStatistics: data,
          errorMessage: null
        });
      },
      onerror: function(err) {
        self.setState({
          errorMessage: 'İstatistikleriniz oluşması için en az bir test çözmüş olmalısınız!'
        });
      }
    })
  }

  getCategoryStatistics() {
    let self = this;
    _xhr.get({
      url: _categoryStatisticsUrl,
      onsuccess: function({data}) {
        self.setState({
          categoryStatistics: data
        })
      },
      onerror: function(err) {
      }
    })
  }

  getSubjectStatistics() {
    let self = this;
    _xhr.get({
      url: _subjectStatisticsUrl,
      onsuccess: function({data}) {
        self.setState({
          subjectStatistics: data
        })
      },
      onerror: function(err) {
      }
    })
  }

  componentDidMount() {
    this.getTotalStatistics();
    this.getCategoryStatistics();
    this.getSubjectStatistics();
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  render() {
    return (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-hourglass-3"></i>
                <b>İSTATİSTİKLER</b>
              </CardHeader>
              {
                this.state.errorMessage &&
                <CardBody>
                  <p>{this.state.errorMessage}</p>
                </CardBody>
              }
              {
                this.state.totalStatistics &&
                <CardBody>
                  <Row>
                    <Col md="4">
                      <div className="statistics-info">
                        <div className="statistics-item">
                          <p><span>Toplam Sınav</span>: <b>{this.state.totalStatistics.quizCount}</b></p>
                          <p><span>Deneme Sınavı</span>: <b>{this.state.totalStatistics.denemeCount}</b></p>
                          <p><span>Konu Testi</span>: <b>{this.state.totalStatistics.konuCount}</b></p>
                        </div>
                        <div className="statistics-item">
                          <p><span>Toplam Soru</span>: <b>{this.state.totalStatistics.totalQuestion}</b></p>
                          <p><span>Doğru</span>: <b>{this.state.totalStatistics.correctCount} (%{parseFloat(this.state.totalStatistics.correctPercent).toFixed(2)})</b></p>
                          <p><span>Yanlış</span>: <b>{this.state.totalStatistics.falseCount} (%{parseFloat(this.state.totalStatistics.falsePercent).toFixed(2)})</b></p>
                          <p><span>Boş</span>: <b>{this.state.totalStatistics.emptyCount} (%{parseFloat(this.state.totalStatistics.emptyPercent).toFixed(2)})</b></p>
                        </div>
                        <div className="statistics-item">
                          <p><span>Toplam Sınav Süresi</span>:&nbsp;
                            <b>
                              {this.state.totalStatistics.totalTimeHour.toString().padStart(2, '0')}:
                              {this.state.totalStatistics.totalTimeMinutes.toString().padStart(2, '0')}:
                              {this.state.totalStatistics.totalTimeSeconds.toString().padStart(2, '0')}
                            </b></p>
                          <p><span>Soru başına</span>: <b>
                            {this.state.totalStatistics.timePerQuestionHour.toString().padStart(2, '0')}:
                            {this.state.totalStatistics.timePerQuestionMinutes.toString().padStart(2, '0')}:
                            {this.state.totalStatistics.timePerQuestionSeconds.toString().padStart(2, '0')}
                          </b></p>
                        </div>
                      </div>
                    </Col>
                    <Col md="8">
                      <Pie data={data} />
                    </Col>
                  </Row>
                </CardBody>
              }
              {
                this.state.categoryStatistics &&
                <CardBody>
                  <h4>Soru Tiplerine Göre Sonuçlar</h4>
                  <Table>
                    <thead>
                    <tr>
                      <th>Soru Tipi</th>
                      <th>Soru Sayısı</th>
                      <th>Doğru</th>
                      <th>Yanlış</th>
                      <th>Boş</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.categoryStatistics.map(item => {
                        return (
                          <tr key={item.categoryId}>
                            <td>{item.categoryName}</td>
                            <td>{item.totalQuestion}</td>
                            <td>{item.correctCount} (%{parseFloat(item.correctPercent).toFixed(2)})</td>
                            <td>{item.falseCount} (%{parseFloat(item.falsePercent).toFixed(2)})</td>
                            <td>{item.emptyCount} (%{parseFloat(item.emptyPercent).toFixed(2)})</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              }
              {
                this.state.subjectStatistics &&
                <CardBody>
                  <h4>Konulara Göre Sonuçlar</h4>
                  <Table>
                    <thead>
                    <tr>
                      <th>Konu</th>
                      <th>Soru Sayısı</th>
                      <th>Doğru</th>
                      <th>Yanlış</th>
                      <th>Boş</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.subjectStatistics.map(item => {
                        return (
                          <tr key={item.subjectId}>
                            <td>{item.subjectName}</td>
                            <td>{item.totalQuestion}</td>
                            <td>{item.correctCount} (%{parseFloat(item.correctPercent).toFixed(2)})</td>
                            <td>{item.falseCount} (%{parseFloat(item.falsePercent).toFixed(2)})</td>
                            <td>{item.emptyCount} (%{parseFloat(item.emptyPercent).toFixed(2)})</td>
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </Table>
                </CardBody>
              }
            </Card>
          </Col>
        </Row>
    );
  }
}

export default Statistics;
